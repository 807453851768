import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchResultCard from '../../components/SearchResultCard';
import SmsUpdate from '../../components/SmsUpdate';
import Status from '../../components/Status';
import { Translate } from '../../i18n/translate';
import { selectFallbackUrl } from '../../store/slices/main/mainSlice';
import { formatDate, openTrackingOnFallBackUrl } from '../../utils/helper';

const Tracking = ({ trackingResponse }) => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [trackingData, setTrackingData] = useState(null);
  const fallbackUrl = useSelector(selectFallbackUrl);

  useEffect(() => {
    if (selectedIndex != null && trackingResponse.trackingResponseList.length > 1) {
      const selected = trackingResponse.trackingResponseList[selectedIndex];
      setTrackingData({
        ...selected,
        referenceId: trackingResponse.reference,
      });
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (trackingResponse.trackingResponseList.length == 1) {
      const selected = trackingResponse.trackingResponseList[0];
      if (selected.transactionStatusList.length == 0) {
        openTrackingOnFallBackUrl(
          fallbackUrl,
          trackingResponse.reference,
          selected.isRefund ? 'REFUND' : 'PAYMENT',
        );
        return;
      }
      setTrackingData({
        ...selected,
        referenceId: trackingResponse.reference,
      });
    }
  }, []);

  const cleanActionDates = (transactions) => {
    let lastValidDate = null;
    return transactions.map((transaction) => {
      const newTransaction = { ...transaction };
      const currentActionDate = newTransaction.actionDate;

      if (
        currentActionDate &&
        lastValidDate &&
        new Date(currentActionDate) < new Date(lastValidDate)
      ) {
        newTransaction.actionDate = '';
      } else if (currentActionDate) {
        lastValidDate = currentActionDate;
      }

      return newTransaction;
    });
  };

  if (!trackingResponse) {
    return <></>;
  }

  if (trackingResponse.trackingResponseList.length > 1 && selectedIndex == null) {
    // mulitple result with same reference id
    const result = trackingResponse.trackingResponseList.map((item) => {
      return {
        reference: trackingResponse.reference,
        institution: item.variables.clientDisplayName,
        createdOn: item.transactionStatusList[0].actionDate,
      };
    });
    return (
      <>
        <div className='text-18-bold sm:text-24-bold'>
          <Translate value='PT_TRACK_PAYMENT_HEADER' />
        </div>
        <p className='text-16-bold my-5'>
          <Translate value='PT_SEARCH_RES_HEADER' />
        </p>
        <SearchResultCard list={result} selectTrackObject={setSelectedIndex} />
      </>
    );
  }

  if (!trackingData) {
    return;
  }

  const {
    transactionStatusList,
    isRefund,
    referenceId,
    transactionOutcome,
    receivableTransactionId,
    showSMSDialog,
    variables,
  } = trackingData;

  const orderedList = transactionStatusList.slice().sort((a, b) => a.orderNo - b.orderNo);

  const orderedTrackingList = isRefund ? cleanActionDates(orderedList) : orderedList;

  return (
    <div className='container max-w-[890px] bg-white m-auto'>
      <div>
        <p className='text-16-bold sm:text-24-bold text-center mb-3 sm:mb-7'>
          {' '}
          {!isRefund ? (
            <Translate value='PT_PAYMENT_STATUS' />
          ) : (
            <Translate value='PT_REFUND_STATUS' />
          )}
        </p>
        <p className='text-sm-medium sm:text-16-medium text-center mb-7 sm:mb-14'>
          {!isRefund ? (
            <Translate value='PT_PAYMENT_REF_ID' />
          ) : (
            <Translate value='PT_REFUND_REF_ID' />
          )}{' '}
          - {referenceId}
        </p>
      </div>
      {orderedTrackingList.map((obj, index) => (
        <Status
          currentStatusIndex={index}
          statusObj={obj}
          key={index}
          totalStatus={transactionStatusList.length}
          success={transactionOutcome}
          nextStatusObj={
            index <= orderedTrackingList.length - 1 ? orderedTrackingList[index + 1] : null
          }
          variables={{
            ...variables,
            dealReference: referenceId,
            actionDate: formatDate(obj.actionDate),
          }}
        />
      ))}
      {transactionOutcome && !isRefund && showSMSDialog && (
        <div className='py-5 mt-12 border-t border-gray5'>
          <SmsUpdate
            pledgeId={receivableTransactionId}
            payerCountryCode={variables?.payerCountryCode}
          />
        </div>
      )}
    </div>
  );
};

export default Tracking;
